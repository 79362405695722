/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || | 
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v2.0.0 Beta
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)


=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
// Chakra imports
import { Box, Button, Icon, Flex, Image, Link, Text } from "@chakra-ui/react";
// Assets
import dashboard from "assets/img/pro/upgrade/main-dashboard-pro-hero.png";
// import dashboard from "assets/img/pro/upgrade/upgrade-pro-main-dashboard.png";
import balance from "assets/img/pro/upgrade/balance-card-pro.png";
import card from "assets/img/pro/upgrade/light-card-pro.png";
import chart from "assets/img/pro/upgrade/main-chart-pro.png";
import temperature from "assets/img/pro/upgrade/temperature-card-pro.png";
import wallet from "assets/img/pro/upgrade/wallet-card-pro.png";
import NavbarStatic from "components/navbar/NavbarStatic";
import { MdChevronRight } from "react-icons/md";
import Hero from "assets/img/pro/black-friday/logo-bf-large.png";
import Left from "assets/img/pro/black-friday/hero-image-left.png";
import Right from "assets/img/pro/black-friday/hero-image-right.png";
import Associates from "sections/pro/associates";
import Countdown from "components/countdownhero";
import ribbon from "assets/img/pro/black-friday/ribbon-large.png";

// Custom components
import InnerContent from "layouts/innerContent";

export default function Upgrade() {
  // Chakra Color Mode
  return (
    <Flex
      w="100%"
      overflow="hidden"
      position={"relative"}
      maxW="100%"
      direction={{ base: "column" }}
      bg="radial-gradient(58.11% 44.54% at 51.59% -9.61%, #B4B0FE 0%, #363285 22.92%, #110D5B 42.71%, #050327 88.54%)"
      borderBottomRadius={{ base: "60px", md: "80px" }}
    >
      <NavbarStatic />
      <InnerContent
        w={{ "2xl": "1560px" }}
        maxW={{ base: "100%" }}
        mx={{ base: "20px", md: "0px", lg: "0px", "2xl": "auto" }}
        borderRadius={{ base: "8px", md: "14px", lg: "16px" }}
      >
        <Flex
          maxW="100%"
          direction="column"
          // zIndex="5"
          width="stretch"
          mb={{ base: "0px", md: "30px" }}
          pt={{
            base: "30px",
            md: "40px",
            xl: "70px",
          }}
          position="relative"
        >
          <Image
            src={Left}
            w={{ base: "60px", md: "160px", lg: "160px", xl: "230px" }}
            top={{ base: "50px", md: "0px" }}
            mx="auto"
            position={"absolute"}
            left={{
              base: "-20px",
              md: "20px",
              lg: "100px",
              xl: "200px",
              "2xl": "240px",
            }}
          />
          <Image
            src={Right}
            w={{ base: "60px", md: "160px", lg: "160px", xl: "230px" }}
            top={{ base: "50px", md: "0px" }}
            mx="auto"
            position={"absolute"}
            right={{
              base: "-20px",
              md: "20px",
              lg: "100px",
              xl: "200px",
              "2xl": "240px",
            }}
          />
          <Flex
            direction="column"
            mx="auto"
            mb="40px"
            px={{ base: "0px", md: "40px", xl: "0px" }}
            maxW={{ base: "100%", lg: "100%", xl: "74%", "2xl": "68%" }}
            textAlign="center"
          >
            <Image src={Hero} w="530px" mx="auto" />
            <Text
              mb={{ base: "30px", md: "40px" }}
              color={"white"}
              alignSelf="center"
              fontSize={{ base: "md", md: "md", xl: "lg" }}
              lineHeight={{ base: "28px", md: "30px" }}
              letterSpacing="0px"
              fontWeight={"500"}
              w={{
                base: "90%",
                md: "65%",
                lg: "56%",
                xl: "60%",
                "2xl": "56%",
              }}
            >
              Hurry up! Take advantage of the Winter Special offers today and
              save up to 53% on your Horizon UI PRO order!
            </Text>
            <Flex
              align="center"
              direction={{ base: "column", md: "row" }}
              justifyContent={{ base: "center", lg: "center" }}
            >
              <Link href="#pricing">
                <Button
                  variant="campaign"
                  py="20px"
                  px="16px"
                  fontSize="sm"
                  borderRadius="45px"
                  me={{ base: "0px", md: "14px" }}
                  mb={{ base: "20px", md: "0px" }}
                  w={{ base: "300px", md: "210px" }}
                  h="54px"
                >
                  Grab the Offer now
                  <Icon as={MdChevronRight} color="white" h="16px" w="16px" />
                </Button>
              </Link>
              <Link href="https://horizon-ui.com/campaign/#bundle">
                <Button
                  variant="explore"
                  py="20px"
                  px="16px"
                  fontSize="sm"
                  borderRadius="45px"
                  me={{ base: "0px", md: "14px" }}
                  mb={{ base: "20px", md: "0px" }}
                  w={{ base: "300px", md: "210px" }}
                  h="54px"
                >
                  Explore the Bundle
                  <Icon as={MdChevronRight} color="white" h="16px" w="16px" />
                </Button>
              </Link>
              <Link isExternal="true" href="https://horizon-ui.com/chakra-pro">
                <Button
                  bg="transparent"
                  border="1px solid"
                  borderColor="whiteAlpha.300"
                  _hover={{ bg: "whiteAlpha.300" }}
                  _active={{ bg: "whiteAlpha.200" }}
                  color="white"
                  fontSize="sm"
                  borderRadius="45px"
                  my="auto"
                  w={{ base: "300px", md: "180px" }}
                  h="54px"
                >
                  See live preview
                </Button>
              </Link>
            </Flex>
            <Countdown date="15 February 2025" />
          </Flex>
        </Flex>
        <Flex mb="30px">
          <Box mt="auto" position="relative">
            <Flex position={"relative"}>
              <Image
                alt="premium react admin template preview"
                zIndex={"2"}
                w={{ base: "330px", md: "640px", lg: "800px", xl: "1170px" }}
                position="relative"
                src={dashboard}
              />
              <Image
                zIndex={"4"}
                maxH="max-content"
                position="absolute"
                borderRadius={{ base: "4px", md: "14px", lg: "16px" }}
                overflow="hidden"
                w={{ base: "150px", md: "244px", lg: "244px" }}
                top={{ base: "-40px", md: "-65px", lg: "-65px" }}
                right={{
                  base: "40px",
                  md: "4px",
                  lg: "0px",
                  xl: "86px",
                }}
                src={ribbon}
              />
              <Image
                zIndex={"2"}
                maxH="max-content"
                position="absolute"
                borderRadius={{ base: "4px", md: "14px", lg: "16px" }}
                overflow="hidden"
                transform={"rotate(15deg)"}
                w={{ base: "70px", md: "120px", lg: "140px", xl: "200px" }}
                boxShadow={
                  "19.3196px 32.6018px 84.5231px 2.82786px rgba(60, 76, 92, 0.28)"
                }
                top={{ base: "40px", md: "64px", lg: "89px" }}
                left={{
                  base: "-40px",
                  md: "-74px",
                  lg: "-100px",
                  xl: "-196px",
                }}
                src={temperature}
              />
              <Image
                alt="premium react admin template balance card"
                zIndex={"2"}
                maxH="max-content"
                position="absolute"
                borderRadius={{ base: "4px", md: "14px", lg: "16px" }}
                overflow="hidden"
                w={{ base: "70px", md: "130px", lg: "156px", xl: "260px" }}
                transform={"rotate(-9.5deg)"}
                boxShadow={
                  "18.8829px 31.865px 82.6129px 2.76395px rgba(60, 76, 92, 0.28)"
                }
                left={{
                  base: "-40px",
                  md: "-60px",
                  lg: "-98px",
                  xl: "-200px",
                }}
                bottom={{
                  base: "-20px",
                  md: "-40px",
                  lg: "-40px",
                  xl: "40px",
                }}
                src={balance}
              />
              <Image
                alt="premium react admin template crypto chart card"
                zIndex={"2"}
                maxH="max-content"
                position="absolute"
                borderRadius={{ base: "4px", md: "14px", lg: "16px" }}
                overflow="hidden"
                w={{ base: "70px", md: "140px", lg: "140px", xl: "192px" }}
                transform={"rotate(-15deg)"}
                boxShadow={"16px 27px 70px 2.34196px rgba(60, 76, 92, 0.28)"}
                top={{
                  base: "34px",
                  md: "30px",
                }}
                right={{
                  base: "-40px",
                  md: "-90px",
                  lg: "-110px",
                  xl: "-167px",
                }}
                src={card}
              />
              <Image
                alt="premium react admin template wallet card"
                zIndex={"2"}
                maxH="max-content"
                position="absolute"
                borderRadius={{ base: "4px", md: "14px", lg: "16px" }}
                overflow="hidden"
                transform={"rotate(15deg)"}
                boxShadow={"16px 27px 70px 2.34196px rgba(60, 76, 92, 0.28)"}
                right={{
                  base: "-40px",
                  md: "-60px",
                  lg: "-130px",
                  xl: "-200px",
                }}
                w={{ base: "70px", md: "130px", lg: "200px", xl: "264px" }}
                top={{ base: "164px", md: "274px", lg: "333px" }}
                src={wallet}
              />
              <Image
                alt="premium react admin template chart card"
                zIndex={"2"}
                maxH="max-content"
                position="absolute"
                borderRadius={{ base: "4px", md: "14px", lg: "16px" }}
                overflow="hidden"
                w={{ base: "130px", md: "270px", lg: "328px", xl: "464px" }}
                left={{ base: "54px", md: "90px", lg: "120px", xl: "190px" }}
                boxShadow={
                  "15.5175px 26.1858px 67.8892px 2.27134px rgba(60, 76, 92, 0.28)"
                }
                top={{ base: "32px", md: "70px", lg: "86px", xl: "107px" }}
                src={chart}
              />
            </Flex>
          </Box>
        </Flex>
        <Associates />
      </InnerContent>
    </Flex>
  );
}
